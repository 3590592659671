import { createRouter, createWebHistory } from 'vue-router';
import store from '@/configure/store';
import homePage from './homePage';
import indexSystem from '@/configure/commons/index';

let homePageRoutes = [];

homePageRoutes = homePageRoutes.concat(homePage);
const routes = [
    {
        path: '/',

        // component: () => import('@/pages/commonModule/indexAdminFrame/index'),
        component: () =>
            import(
                /* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/index'
            ),
        // children: [].concat(homePageRoutes)
        meta: {
            title: '培训学习',
        },
    },
    {
        path: '/homePage/indexHomePageDetails',
        component: () =>
            import(
                /* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataShow.vue'
            ),
        meta: {
            title: '培训学习',
        },
    },
    {
        path: '/homePage/indexHomePageExamination',
        component: () =>
            import(
                /* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataExamination.vue'
            ),
        meta: {
            title: '培训考试',
        },
    },
    {
        path: '/homePage/liveStreamingDetails',
        component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/liveStreamingDetails.vue'),
        meta:{
            title:'培训学习'
        }
        
    },
    {
        path: '/homePage/indexHomePageExamResults',
        component: () =>
            import(
                /* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataExamResults.vue'
            ),
        meta: {
            title: '成绩详情',
        },
    },
    {
        path: '/homePage/DataGraphicAndTextCourse',
        component: () =>
            import(
                /* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataGraphicAndTextCourse.vue'
            ),
        meta: {
            title: '培训学习',
        },
    },
    {
        path: '/homePage/DataVideoLessons',
        component: () =>
            import(
                /* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataVideoLessons.vue'
            ),
        meta: {
            title: '培训学习',
        },
    },

    // {
    //     path: '/indexAdminLogin',
    //     component: () => import('@/pages/commonModule/indexAdminLogin/index'),
    // },
    {
        path: '/:pathMatch(.*)',
        name: '/index404',
        component: () => import('@/pages/commonModule/index404/index'),
    },
    {
        path: '/iframeView',
        component: () =>
            import(
                /* webpackChunkName: "iframeView" */ '@/pages/iframeView/index.vue'
            ),
    },
];

const router = createRouter({
    history: createWebHistory('trainH5Web'),
    routes,
});

router.beforeEach((to, from, next) => {
    // document.title = store.state.systemMetaBaseData.systemName;
    if (to.query.token) {
        store.commit('saveTokenData', to.query.token);
        next();
    }
    // if (to.path == '/iframeView') {
    //     next();
    // }
    // if (to.path !== '/indexAdminLogin') {
    //     const token = store.state.userData.token;
    //     if (token == '' || token == undefined) {
    //         next('/indexAdminLogin');
    //     } else {
    //         next();
    //     }
    // } else {
    //     next();
    // }
    next();
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router;
