import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        userData: {
            token: '',
            businessUserData:null // 集合
        },
    
    },
    mutations: {
        // 存token
        saveTokenData: function (state, token) {
            state.userData.token =token;
        },
        // 存集合
        saveBusinessUserData:function (state,data) {
            state.userData.businessUserData =data;
        },
        // 删除token
        deleteTokenData: function (state) {
            state.userData.token = '';
        },

    },
    actions: {},
    modules: {},
    plugins: [createPersistedState()],
});
