const memberModuleMenu = [
    // {
    //     path: '/homePage/indexHomePage',
    //     component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/index'),
    //     meta:{
    //         title:'培训学习'
    //     }
        
    // },
    {
        path: '/homePage/liveStreamingDetails',
        component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/liveStreamingDetails.vue'),
        meta:{
            title:'培训学习'
        }
        
    },
    {
        path: '/homePage/indexHomePageDetails',
        component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataShow.vue'),
        meta:{
            title:'培训学习'
        }
        

    },
    {
        path: '/homePage/indexHomePageExamination',
        component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataExamination.vue'),
        meta:{
            title:'培训考试'
        }
    },
    {
        path: '/homePage/indexHomePageExamResults',
        component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataExamResults.vue'),
        meta:{
            title:'成绩详情'
        }
    },
    {
        path:'/homePage/DataGraphicAndTextCourse',
        component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataGraphicAndTextCourse.vue'),
        meta:{
            title:'培训学习'
        }

    },
    {
        path:'/homePage/DataVideoLessons',
        component: () => import(/* webpackChunkName: "homePage" */ '@/pages/homePage/indexHomePage/Data/DataVideoLessons.vue'),
        meta:{
            title:'培训学习'
        }

    },


    

   

  
]

export default memberModuleMenu
