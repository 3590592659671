import '@/style/baseSystemStyle.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/configure/router'
import store from '@/configure/store'
import 'vant/es/toast/style';
import 'lib-flexible/flexible.js'
import VueHashCalendar from 'vue3-hash-calendar';
import 'vue3-hash-calendar/es/index.css';
const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueHashCalendar);



app.mount('#app')
