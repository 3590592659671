/// 将分数转换成对应的星级, 0.5分界,向上取值,如2.1分=2颗半星星,4.6分=5颗星
export const filterStars = (stars) => {
  var integer = (stars / 10).toString().substring(0,1)
  var remainder = stars % 10;

  var decimal = 0;
  if (remainder > 0) {
      // 如果余数大于0
      if (remainder <= 5) {
          // 且小于等于5,则小数为0.5,半颗星
          decimal = 0.5;
      } else {
          // 如果大于5,则小数位为1;
          decimal = 1;
      }
  }
  return Number(integer)  + Number(decimal) ;
}



// 数字金额转大写
export const numToCny = (money) => {
  // 汉字的数字
  // eslint-disable-next-line no-array-constructor
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  // 基本单位
  // eslint-disable-next-line no-array-constructor
  var cnIntRadice = new Array('', '拾', '佰', '仟')
  // 对应整数部分扩展单位
  // eslint-disable-next-line no-array-constructor
  var cnIntUnits = new Array('', '万', '亿', '兆')
  // 对应小数部分单位
  // eslint-disable-next-line no-array-constructor
  var cnDecUnits = new Array('角', '分', '毫', '厘')
  // 整数金额时后面跟的字符
  var cnInteger = '整'
  // 整型完以后的单位
  var cnIntLast = '元'
  // 最大处理的数字
  var maxNum = 999999999999999.9999
  // 金额整数部分
  var integerNum
  // 金额小数部分
  var decimalNum
  // 输出的中文金额字符串
  var chineseStr = ''
  // 分离金额后用的数组，预定义
  var parts
  // eslint-disable-next-line eqeqeq
  if (money == '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    // 超出最大处理数字
    return ''
  }
  // eslint-disable-next-line eqeqeq
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  // 转换为字符串
  money = money.toString()
  // eslint-disable-next-line eqeqeq
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0
    var IntLen = integerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1)
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      // eslint-disable-next-line eqeqeq
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        // 归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      // eslint-disable-next-line eqeqeq
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  // 小数部分
  // eslint-disable-next-line eqeqeq
  if (decimalNum != '') {
    var decLen = decimalNum.length
    // eslint-disable-next-line no-redeclare
    for (var i = 0; i < decLen; i++) {
      // eslint-disable-next-line no-redeclare
      var n = decimalNum.substr(i, 1)
      // eslint-disable-next-line eqeqeq
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  // eslint-disable-next-line eqeqeq
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
    // eslint-disable-next-line eqeqeq
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}

// 下载
export const downloadFile = (fileName, content) => {
  let aLink = document.createElement('a')
  let blob = base64ToBlob(content) // new Blob([content]);
  let evt = document.createEvent('HTMLEvents')
  evt.initEvent('click', true, true)// initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
  aLink.download = fileName
  aLink.href = URL.createObjectURL(blob)

  // aLink.dispatchEvent(evt);
  aLink.click()
}
// base64转对象
export const base64ToBlob = (code) => {
  let parts = code.split(';base64,')
  let contentType = parts[0].split(':')[1]
  let raw = window.atob(parts[1])
  let rawLength = raw.length

  let uInt8Array = new Uint8Array(rawLength)

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], {type: contentType})
}
// 文件下载
export const openLoad = (type, bese64, name) => {
  if (type.indexOf('image') !== -1) {
    let blob = base64ToBlob(bese64)
    let url = window.URL.createObjectURL(blob)
    console.log(url)
    window.open(url)
  } else {
    downloadFile(name, bese64)
  }
}

// Base64 转 File 文件流
export const base64toFile = (dataurl,filename='file') =>  {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split('/')[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  });
}


