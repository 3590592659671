import axios from 'axios'; // 引入axios

import indexSystem from '@/configure/commons/index';
import { Toast } from 'vant';
import router from '@/configure/router';
import store from '@/configure/store';
import { base64toFile } from '@/util/tool.js';

const Base64 = require('js-base64').Base64;

export const MsgMessage = function (code, msg) {
    switch (parseInt(code)) {
        case 0:
            if (msg !== '') {
                Toast.success(msg);
            }
            break;
        case 1:
            Toast.fail(msg);
            break;
        case 50:
            Toast.fail(msg);
            // store.commit('deleteUserData');
            // router.push('/indexAdminLogin');
            // window.open(indexSystem.mainLoginUrl,'_self')
            break;
        case 51:
            Toast.fail(msg);
            break;
        case 52:
            Toast.fail(msg);
            break;
    }
};



/**
 * 不带token的接口
 * @param options
 * @returns {Promise<*>}
 */
export const requestNoToken = async function (options) {
    options.url = indexSystem.baseApiUrl + options.url;
    options.method = 'post';
    options.headers = { 'Content-Type': 'application/json;charset=utf-8' };

    return new Promise((resolve, reject) => {
        axios(options)
            .then((res) => {
                //  这里主要根据后来返回的数据作判断，请根据实际情况
                if (res.data.code === 0) {
                    MsgMessage(res.data.code, res.data.message);
                    resolve(res.data);
                } else {
                    MsgMessage(res.data.code, res.data.message);
                }
            })
            .catch((err) => {
                Toast.fail('请求失败!');
            });
    });
};

/**
 * 带token的接口  在头上
 * @param options
 * @returns {Promise<undefined|*|Promise<any>>}
 */
export const requestToken = async function (options) {
    const token = store.state.userData.token;
    if (token == '' || token == undefined) {
        router.push('/');
        return;
    }

    options.url = indexSystem.baseApiUrl + options.url;
    options.method = 'post';
    options.headers = {
        'Content-Type': 'application/json;charset=utf-8',
        token
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then((res) => {
                //  这里主要根据后来返回的数据作判断，请根据实际情况
                if (res.data.code === 0) {
                    MsgMessage(res.data.code, res.data.message);
                    resolve(res.data);
                } else {
                    MsgMessage(res.data.code, res.data.message);
                }
            })
            .catch((err) => {
                // console.log(err);
          
                Toast.fail('请求失败!');
            });
    });
};


