const system =
{
  //  baseApiUrl: 'http://192.168.200.245:8171',

  //  baseApiUrl: 'http://192.168.200.252:8101',

  // baseApiUrl: 'http://192.168.200.220:8080',

  //阿里云测试环境
  baseApiUrl: 'https://api.train.test.720.weetion.top'

}

export default system
