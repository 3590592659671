import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted, reactive } from 'vue';
import { requestNoToken } from '@/util/adminRequest.js';
import store from '@/configure/store'; // 启动调用

export default {
  __name: 'App',

  setup(__props) {
    onMounted(() => {
      // 禁止 手势缩放
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
      });
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view);
    };
  }

};